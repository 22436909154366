<template>
  <div class="main">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap" rel="stylesheet">
    <div class="container">
      <div class="content" >
        <button class="settings-btn" @click="displaySettings">SETTINGS</button>
        <div class="left-slide" v-show="!settingsActive">
          <div class="weapon">
            <div class="big locked"></div>
            <div v-bind:class="{ refletsBig: locked.weapon }"></div>
            <div class="weapon-infos">
              <h3 class="weapon-name" :class="selectedWeapon.name">{{ selectedWeapon.name }}</h3>
              <h3 class="weapon-shape" :class="selectedWeapon.shape">{{ selectedWeapon.shape }}</h3>
            </div>
            <img :src="selectedWeapon.src">
          </div>
          <div class="side-column">
            <div class="skills" v-bind:class="{ ok: locked.skills }">
              <ul>
                <li><i class="skill row0" v-bind:class="{ right: skills[0].alt }"></i></li>
                <li><i class="skill row1" v-bind:class="{ right: skills[1].alt }"></i></li>
                <li><i class="skill row2" v-bind:class="{ right: skills[2].alt }"></i></li>
                <li><i class="skill row3" v-bind:class="{ right: skills[3].alt }"></i></li>
                <li><i class="skill row4" v-bind:class="{ right: skills[4].alt }"></i></li>
                <li><i class="skill row5" v-bind:class="{ right: skills[5].alt }"></i></li>
                <li><i class="skill row6" v-bind:class="{ right: skills[6].alt }"></i></li>
                <li><i class="skill row7" v-bind:class="{ right: skills[7].alt }"></i></li>
                <li><i class="skill row8" v-bind:class="{ right: skills[8].alt }"></i></li>
                <li><i class="skill row9" v-bind:class="{ right: skills[9].alt }"></i></li>
                <li><i class="skill row10" v-bind:class="{ right: skills[10].alt }"></i></li>
                <li><i class="skill row11" v-bind:class="{ right: skills[11].alt }"></i></li>
              </ul>
            </div>
            <div class="side">
                <div class="trinket">
                  <div class="small locked"></div>
                  <div v-bind:class="{ refletsSmall: locked.trinket }"></div>
                  <span class="helper"></span><img :src="selectedTrinket.src" alt="">
                </div>
              <div class="trinket">
                <div class="small locked"></div>
                <div v-bind:class="{ refletsSmall: locked.companion }"></div>
                <span class="helper"></span><img :src="selectedCompanion.src">
              </div>
            </div>
          </div>
          <button class="spin-btn" @click="spinz">Ask the Fates</button>
        </div>
        <div class="settings" v-show="settingsActive">
          <div>
            <h2>Weapons</h2>
            <ul>
              <li v-for="w in weapons" :key="w.id">
                <input type="checkbox" :data-name="w.id" :id="w.id" v-model="w.active"><label :for="w.id">{{ w.name }} - {{ w.shape }}</label>
              </li>
            </ul>
          </div>
          <div>
            <h2>Trinkets</h2>
            <ul>
              <li v-for="t in trinkets" :key="t.id">
                <input type="checkbox" :data-name="t.id" :id="t.id"  v-model="t.active"><label :for="t.id">{{ t.name }}</label>
              </li>
            </ul>
          </div>
          <div>
            <h2>Companions</h2>
            <ul>
              <li v-for="c in companions" :key="c.id">
                <input type="checkbox" :date-name="c.id" :id="c.id"  v-model="c.active"><label :for="c.id">{{ c.name }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

let ROOT_PATH = 'https://proxym4.tv';

export default {
  name: 'Hades-Roulette',
  props: {
    msg: String
  },
  data: function() {
    return {
      weapons: [
        { id: 'sword-zagreus', name: 'stygius', shape:'zagreus', active: true, src: require("../assets/hades/weapon/sword-zagreus.jpg"), desc: '' },
        { id: 'sword-nemesis', name: 'stygius', shape:'nemesis', active: true, src: require("../assets/hades/weapon/sword-nemesis.jpg"), desc: '' },
        { id: 'sword-poseidon', name: 'stygius', shape:'poseidon', active: true, src: require("../assets/hades/weapon/sword-poseidon.jpg"), desc: '' },
        { id: 'sword-arthur', name: 'stygius', shape:'arthur', active: true, src: require("../assets/hades/weapon/sword-arthur.jpg"), desc: '' },
        { id: 'spear-zagreus', name: 'varatha', shape:'zagreus', active: true, src: require("../assets/hades/weapon/spear-zagreus.jpg"), desc: '' },
        { id: 'spear-achille', name: 'varatha', shape:'achilles', active: true, src: require("../assets/hades/weapon/spear-achille.jpg"), desc: '' },
        { id: 'spear-hades', name: 'varatha', shape:'hades', active: true, src: require("../assets/hades/weapon/spear-hades.jpg"), desc: '' },
        { id: 'spear-guanyu', name: 'varatha', shape:'guan-yu', active: true, src: require("../assets/hades/weapon/spear-guanyu.jpg"), desc: '' },
        { id: 'shield-zagreus', name: 'aegis', shape:'zagreus', active: true, src: require("../assets/hades/weapon/shield-zagreus.jpg"), desc: '' },
        { id: 'shield-chaos', name: 'aegis', shape:'chaos', active: true, src: require("../assets/hades/weapon/shield-chaos.jpg"), desc: '' },
        { id: 'shield-zeus', name: 'aegis', shape:'zeus', active: true, src: require("../assets/hades/weapon/shield-zeus.jpg"), desc: '' },
        { id: 'shield-beowulf', name: 'aegis', shape:'beowulf', active: true, src: require("../assets/hades/weapon/shield-beowulf.jpg"), desc: '' },
        { id: 'bow-zagreus', name: 'coronacht', shape:'zagreus', active: true, src: require("../assets/hades/weapon/bow-zagreus.jpg"), desc: '' },
        { id: 'bow-chiron', name: 'coronacht', shape:'chiron', active: true, src: require("../assets/hades/weapon/bow-chiron.jpg"), desc: '' },
        { id: 'bow-hera', name: 'coronacht', shape:'hera', active: true, src: require("../assets/hades/weapon/bow-hera.jpg"), desc: '' },
        { id: 'bow-rama', name: 'coronacht', shape:'rama', active: true, src: require("../assets/hades/weapon/bow-rama.jpg"), desc: '' },
        { id: 'fist-zagreus', name: 'malphon', shape:'zagreus', active: true, src: require("../assets/hades/weapon/fist-zagreus.jpg"), desc: '' },
        { id: 'fist-talos', name: 'malphon', shape:'talos', active: true, src: require("../assets/hades/weapon/fist-talos.jpg"), desc: '' },
        { id: 'fist-demeter', name: 'malphon', shape:'demeter', active: true, src: require("../assets/hades/weapon/fist-demeter.jpg"), desc: '' },
        { id: 'fist-gilgamesh', name: 'malphon', shape:'gilgamesh', active: true, src: require("../assets/hades/weapon/fist-gilgamesh.jpg"), desc: '' },
        { id: 'gun-zagreus', name: 'exagryph', shape:'zagreus', active: true, src: require("../assets/hades/weapon/gun-zagreus.jpg"), desc: '' },
        { id: 'gun-eris', name: 'exagryph', shape:'eris', active: true, src: require("../assets/hades/weapon/gun-eris.jpg"), desc: '' },
        { id: 'gun-hestia', name: 'exagryph', shape:'hestia', active: true, src: require("../assets/hades/weapon/gun-hestia.jpg"), desc: '' },
        { id: 'gun-lucifer', name: 'exagryph', shape:'lucifer', active: true, src: require("../assets/hades/weapon/gun-lucifer.jpg"), desc: '' },
      ],
      selectedWeapon: { id: 'sword-zagreus', name: 'stygius', shape:'zagreus', active: true, src: require("../assets/hades/weapon/sword-zagreus.jpg"), desc: '' },
      skills: [
        { id: 'skill-0', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-1', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-2', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-3', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-4', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-5', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-6', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-7', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-8', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-9', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-10', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
        { id: 'skill-11', alt: false, name: 'Violé', desc: '', nameAlt: 'Ver', descAlt: '' },
      ],
      trinkets: [
       { id: 'Old_Spiked_Collar', name : "Old Spiked Collar", active: true, src: require("../assets/hades/trinket/Old_Spiked_Collar.png"), desc: '' },
       { id: 'Myrmidon_Bracer', name : "Myrmidon Bracer", active: true, src: require("../assets/hades/trinket/Myrmidon_Bracer.png"), desc: '' },
       { id: 'Black_Shawl', name : "Black Shawl", active: true, src: require("../assets/hades/trinket/Black_Shawl.png"), desc: '' },
       { id: 'Pierced_Butterfly', name : "Pierced Butterfly", active: true, src: require("../assets/hades/trinket/Pierced_Butterfly.png"), desc: '' },
       { id: 'Bone_Hourglass', name : "Bone Hourglass", active: true, src: require("../assets/hades/trinket/Bone_Hourglass.png"), desc: '' },
       { id: 'Chthonic_Coin_Purse', name : "Chtonic Coin Pursed", active: true, src: require("../assets/hades/trinket/Chthonic_Coin_Purse.png"), desc: '' },
       { id: 'Skull_Earring', name : "Skull Earring", active: true, src: require("../assets/hades/trinket/Skull_Earring.png"), desc: '' },
       { id: 'Distant_Memory', name : "Distant Memory", active: true, src: require("../assets/hades/trinket/Distant_Memory.png"), desc: '' },
       { id: 'Harpy_Feather_Duster', name : "Harpy Feather Duster", active: true, src: require("../assets/hades/trinket/Harpy_Feather_Duster.png"), desc: '' },
       { id: 'Lucky_Tooth', name : "Lucky Tooth", active: true, src: require("../assets/hades/trinket/Lucky_Tooth.png"), desc: '' },
       { id: 'Thunder_Signet', name : "Thunder Signet", active: true, src: require("../assets/hades/trinket/Thunder_Signet.png"), desc: '' },
       { id: 'Conch_Shell', name : "Conch Shell", active: true, src: require("../assets/hades/trinket/Conch_Shell.png"), desc: '' },
       { id: 'Owl_Pendant', name : "Owl Pendant", active: true, src: require("../assets/hades/trinket/Owl_Pendant.png"), desc: '' },
       { id: 'Eternal_Rose', name : "Eternal Rose", active: true, src: require("../assets/hades/trinket/Eternal_Rose.png"), desc: '' },
       { id: 'Blood-Filled_Vial', name : "Blood-Filled Vial", active: true, src: require("../assets/hades/trinket/Blood-Filled_Vial.png"), desc: '' },
       { id: 'Adamant_Arrowhead', name : "Adamant Arrowhead", active: true, src: require("../assets/hades/trinket/Adamant_Arrowhead.png"), desc: '' },
       { id: 'Overflowing_Cup', name : "Overflowing Cup", active: true, src: require("../assets/hades/trinket/Overflowing_Cup.png"), desc: '' },
       { id: 'Lambent_Plume', name : "Lambent Plume", active: true, src: require("../assets/hades/trinket/Lambent_Plume.png"), desc: '' },
       { id: 'Frostbitten_Horn', name : "Frostbitten Horn", active: true, src: require("../assets/hades/trinket/Frostbitten_Horn.png"), desc: '' },
       { id: 'Cosmic_Egg', name : "Cosmic Egg", active: true, src: require("../assets/hades/trinket/Cosmic_Egg.png"), desc: '' },
       { id: 'Shattered_Shackle', name : "Shattered Shackle", active: true, src: require("../assets/hades/trinket/Shattered_Shackle.png"), desc: '' },
       { id: 'Evergreen_Acorn', name : "Evergreen Acorn", active: true, src: require("../assets/hades/trinket/Evergreen_Acorn.png"), desc: '' },
       { id: 'Broken_Spearpoint', name : "Broken Spearpoint", active: true, src: require("../assets/hades/trinket/Broken_Spearpoint.png"), desc: '' },
       { id: 'Pom_Blossom', name : "Pom Blossom", active: true, src: require("../assets/hades/trinket/Pom_Blossom.png"), desc: '' },
       { id: 'Sigil_of_the_Dead', name : "Sigil of the Dead", active: true, src: require("../assets/hades/trinket/Sigil_of_the_Dead.png"), desc: '' }
      ],
      selectedTrinket : { id: 'Old_Spiked_Collar', name : "Old Spiked Collar", active: true, src: require("../assets/hades/trinket/Old_Spiked_Collar.png"), desc: '' },
      companions : [
        { id: 'ant', name : "Ant", active: true, src: require("../assets/hades/companion/ant.png"), desc: '' },
        { id: 'bat', name : "Bat", active: true, src: require("../assets/hades/companion/bat.png"), desc: '' },
        { id: 'cubitos', name : "Cubitos", active: true, src: require("../assets/hades/companion/cubitos.png"), desc: '' },
        { id: 'mouse', name : "Mouse", active: true, src: require("../assets/hades/companion/mouse.png"), desc: '' },
        { id: 'snake', name : "Snake", active: true, src: require("../assets/hades/companion/snake.png"), desc: '' },
        { id: 'soul', name : "Soul", active: true, src: require("../assets/hades/companion/soul.png"), desc: '' }
      ],
      selectedCompanion : { id: 'ant', name : "Ant", active: true, src: require("../assets/hades/companion/ant.png"), desc: '' },
      locked: {
        trinket : false,
        companion : false,
        weapon : false,
        skills : false
      },
      settingsActive : false,
      metaImg : ROOT_PATH + require('../assets/hades/hades-roulette.jpg'),
    };
  },
  metaInfo(){
    return {
      title: 'Hades Roulette',
      meta : [
        /* TWITTER */
        { name: 'twitter:card', content: 'summary'},
        { name: 'twitter:title', content: 'Hades Roulette'},
        { name: 'twitter:description', content: 'Let the Fates decide your gear.'},
        { name: 'twitter:image', content: this.metaImg },
        /* FACEBOOK */
        { property: 'og:title', content: 'Hades Roulette' },
        { property: 'og:site_name', content: 'Proxym4.tv' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: this.metaImg },
        { property: 'og:description', content: 'Let the Fates decide your gear.'},
      ]
    }
  },
  computed: {
      activeWeapons() {
        return this.weapons.filter( (w) => w.active );
      },
      activeTrinkets(){
        return this.trinkets.filter( (t) => t.active );
      },
      activeCompanions(){
        return this.companions.filter( (c) => c.active );
      }
  },
  methods: {
    spinz() {
      this.rollWeapon( 20 + Math.random() * 30 | 0  );
      this.rollTrinket( 20 + Math.random() * 30 | 0  );
      this.rollCompanion( 20 + Math.random() * 30 | 0  );
      this.rollSkills( 20 + Math.random() * 30 | 0  );

      this.locked.trinket = false;
      this.locked.companion = false;
      this.locked.weapon = false;
      this.locked.skills = false;
      
      this.$ga.event("hades","spin");
    },
    rollWeapon( count ){
      setTimeout( () => {
          if( count > 0 ){
            this.selectedWeapon = this.activeWeapons[Math.random() * this.activeWeapons.length | 0];
            count--;
            this.rollWeapon( count );
          }else{
            setTimeout( () => {
              this.locked.weapon = true;
            }, 200 + Math.random() * 600 );
          }
        }, 800 / count );
    },
    rollTrinket( count ){
       if( count > 0 ){
        setTimeout( () => {
          this.selectedTrinket = this.activeTrinkets[ Math.random() * this.activeTrinkets.length | 0  ];
          count--;
          this.rollTrinket( count );
        }, 800 / count );
      }else{
        setTimeout( () => {
          this.locked.trinket = true;
        }, 200 + Math.random() * 600 );
      }
    },
    rollCompanion( count ){
       if( count > 0 ){
        setTimeout( () => {
          this.selectedCompanion = this.activeCompanions[ Math.random() * this.activeCompanions.length | 0  ];
          count--;
          this.rollCompanion( count );
        }, 800 / count );
      }else{
        setTimeout( () => {
          this.locked.companion = true;
        }, 200 + Math.random() * 600 );
      }
    },
    rollSkills( count ){
      if( count > 0 ){
        setTimeout( () => {
          this.skills.forEach((skill) => {
            skill.alt = Math.random() > .5;
          });
          count--;
          this.rollSkills( count );
        }, 800 / count );
      }else{
        setTimeout( () => {
          this.locked.skills = true;
        }, 200 + Math.random() * 600 );
      }
    },
    displaySettings(){
      this.settingsActive = !this.settingsActive;
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  li {
    list-style: none;
  }

  .main{
    background: url("../assets/hades/room.jpg");
    padding: 100px 0;
    background-size: cover;
    
    @media screen and ( max-width: 415px ){
      padding-top: 20px;
    }

    .container{
      background: url("../assets/hades/background.png");
      background-repeat: no-repeat;
      height: 682px;
      margin: auto;
      background-size: cover;
      box-sizing: border-box;
      position: relative;
      max-width: 800px;
      background-position-x: -10px;

      @media screen and ( max-width: 800px ){
        background-position-x: 60%;
      }

      .content {
        margin: 0 auto;
        padding-top: 80px;

        @media screen and ( max-width: 415px ){
          width: 100%;
        }

        .settings {
          color: #fff;
          justify-content: space-between;
          box-sizing: border-box;
          max-width: 510px;
          margin: 0 auto;
          padding-left: 45px;

          @media screen and ( max-width: 800px ){
            padding-left: 0px;
          }

          h2{
            margin: 10px 0;
          }

          ul {
            display: flex;
            margin: 0;
            padding: 0;
            text-align: left;
            font-size: 10px;
            flex-wrap: wrap;
            justify-content: center;

            li {
              width: 31%;
            }
          }

          [type="checkbox"]:not(:checked),
          [type="checkbox"]:checked{
            position: absolute;
            left: -1500px;
            opacity: 0.01;
          }

          [type="checkbox"]:not(:checked) + label,
          [type="checkbox"]:checked + label {
            cursor: pointer;
            line-height: 20px;
            text-transform: capitalize;
          }

          [type="checkbox"] + label::before{
            content:"";
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            margin-right: 5px;
            background: url("../assets/hades/checkbox.png");
            background-size: 100% 200%;
          }

          [type="checkbox"]:not(:checked) + label::before{
            background-position: 0px 100%;
          }

          [type="checkbox"]:checked + label::before{
            background-position: 0px 0px;
          }
        }
      }

      .left-slide {
        display: flex;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;
        padding-top: 30px;
      }

      .weapon {
        position: relative;

        img {
          border-radius: 35px;
        }

        .weapon-infos {
          color: #fff;
          position: absolute;
          width: 100%;
          bottom: 10px;
          padding: 10px;
          box-sizing: border-box;
          font-family: 'Montserrat', sans-serif;
          
          h3 {
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            &.weapon-name {
              &::after{
                content:"";
                height: 1px;
                width: 75%;
                display: block;
                background: #fff;
                margin: 5px auto;
              }

              &.stygius {
                font-size: 25px;
                &::first-letter {
                  font-size: 35px;
                }
              }

              &.varatha {
                font-size: 22px;
                &::first-letter{
                  font-size: 30px;
                }
              }

              &.coronacht {
                font-size: 17px;
                &::first-letter{
                  font-size: 25px;
                }
              }

              &.exagryph {
                font-size: 18px;
                &::first-letter{
                  font-size: 28px;
                }
              }

              &.aegis {
                font-size: 32px;
                &::first-letter{
                  font-size: 45px;
                }
              }

              &.malphon {
                font-size: 20px;
                &::first-letter{
                  font-size: 29px;
                }
              }
            }

            &.weapon-shape {

              &.zagreus {
                font-size: 22px;
                &::first-letter{
                  font-size: 35px;
                }
              }

              &.hades {
                font-size: 30px;
                &::first-letter {
                  font-size: 39px;
                }
              }

              &.hera {
                font-size: 33px;
                &::first-letter {
                  font-size: 50px;
                }
              }

              &.nemesis {
                font-size: 23px;
                &::first-letter {
                  font-size: 32px;
                }
              }

              &.chaos {
                font-size: 27px;
                &::first-letter {
                  font-size: 44px;
                }
              }

              &.arthur {
                font-size: 25px;
                &::first-letter {
                  font-size: 33px;
                }
              }

              &.gilgamesh {
                font-size: 18px;
                &::first-letter {
                  font-size: 25px;
                }
              }

              &.guan-yu {
                font-size: 21px;
                &::first-letter {
                  font-size: 38px;
                }
              }

              &.talos {
                font-size: 21px;
                &::first-letter {
                  font-size: 38px;
                }
              }

              &.chiron {
                font-size: 25px;
                &::first-letter {
                  font-size: 38px;
                }
              }

              &.rama {
                font-size: 33px;
                &::first-letter {
                  font-size: 48px;
                }
              }

              &.zeus {
                font-size: 36px;
                &::first-letter {
                  font-size: 53px;
                }
              }

              &.beowulf {
                font-size: 20px;
                &::first-letter {
                  font-size: 33px;
                }
              }

              &.lucifer {
                font-size: 24px;
                &::first-letter {
                  font-size: 34px;
                }
              }

              &.hestia {
                font-size: 28px;
                &::first-letter {
                  font-size: 40px;
                }
              }

              &.eris {
                font-size: 44px;
                &::first-letter {
                  font-size: 60px;
                }
              }

              &.achilles {
                font-size: 20px;
                &::first-letter {
                  font-size: 32px;
                }
              }
              
              &.poseidon {
                font-size: 20px;
                &::first-letter {
                  font-size: 32px;
                }
              }

            }
          }

        }
      }

      .side-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 25px;

        @media screen and ( max-width: 415px ){
          padding-left: 0px;
        }
      }

      .skills {
        height: 300px;
        filter: grayscale(0.8);

        ul {
          display: flex;
          margin: 0;
          padding: 10px;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          box-sizing: border-box;

          li {
            width: 30%;
            margin-bottom: 20px;
          }
        }

        &.ok {
          filter: grayscale(0);
        }
      }

      .side {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;

        .trinket {
          width: 105px;
          height: 143px;
          position: relative;
          background-size: contain;
          background: url("../assets/hades/trinket-bkg.png");
          border-radius: 15px;
          overflow: hidden;

          img {
            vertical-align: middle;
            width: 65%;
            height: auto;
          }
        }
      }
      
      .spin-btn {
        position: absolute;
        bottom: -15px;
        border: none;
        background: url("../assets/hades/button.png");
        width: 306px;
        height: 88px;
        cursor: pointer;
        color: #fff;
        line-height: 88px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 19px;

        &::first-letter {
          font-size: 25px;
        }

        &:hover {
          background-position: 306px 0px;
          color: #fff266;
          text-shadow: 0px 3px #000;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .locked {
    background: url("../assets/hades/trinket-locked.png");
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;

    &::before {
      background: url("../assets/hades/flower.png");
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      content: "";
      width: 51px;
      height: 27px;
      left: calc(50% - 25px);
      top: -16px;
    }

    &.big {
      background: url("../assets/hades/weapon-locked.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .skill {
    background: url("../assets/hades/skills.png");
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;

    @for $i from 0 through 11 {
      &.row#{$i} {
        background-position-y: -50px*$i;
      }
    }

    &.left {
      background-position-x: 0px;
    }

    &.right {
      background-position-x: 50px;
    }
  }

  .refletsBig {
    position: absolute;
    width: 148px;
    height: 488px;
    background: url("../assets/hades/reflet-big.png");
    animation: big-reflet 0.2s steps( 4 );
    animation-fill-mode: forwards;
  }

  .refletsSmall {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/hades/reflet-small.png");
    background-size: 500% 100%;
    animation: small-reflet 0.2s steps( 4 );
    animation-fill-mode: forwards;
  }

  @keyframes big-reflet {
    from { background-position-x: -0px; }
    to { background-position-x: -592px; }
  }

  @keyframes small-reflet {
    from { background-position-x:  0px; }
    to { background-position-x: -400%; }
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .settings-btn {
    position: absolute;
    z-index: 10;
    right: 161px;
    top: 84px;
    background: none;
    border: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    outline: none;

    @media screen and ( max-width: 600px ){
      right: 10px;
      top: 90px;
    }

    &:hover {
      color: #fff266;
      text-shadow: 0px 3px #000;
    }

    &::first-letter {
      font-size: 20px;
    }

    &:focus {
      outline: none;
    }
  }


</style>
